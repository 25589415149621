/* You can add global styles to this file, and also import other style files */
@import 'variables';

body {
    margin: 0;
    padding: 0;
    background: #f6f6f6;
}

.m-0,
.row {
    margin: 0;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.mb-5 {
    margin-bottom: 3rem !important;
}

.p-0 {
    padding: 0 !important;
}

.py-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.py-2 {
    padding: 0.5rem 0 !important;
}

.p-1 {
    padding: 1rem !important;
}

//alignment 
.vertical-alignment {
    align-items: center;
}

//mat-toolbar 
.mat-toolbar.mat-primary {
    padding: 0;
    height: 70px;

    .navbar-nav {
        button {
            height: 70px;
            font-family: 'Saira', sans-serif;
            text-transform: uppercase;
            padding: 0 20px;
            border-radius: 0;
        }
    }
}

//menu 
button.mat-focus-indicator[aria-expanded="true"]:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #cc3029;
    position: absolute;
    bottom: 0;
}

.mat-menu-panel {
    background: $red-color;
    width: 100vw;
    border-radius: 0 !important;

    button.mat-menu-item {
        line-height: 38px;
        height: 38px;
        font-family: 'Saira', sans-serif;
        color: $white-color;
        text-transform: uppercase;
    }
}

button.mat-menu-item svg {
    display: none;
}

button.mat-menu-item[ng-reflect-menu='[object Object]'] svg {
    display: block;
}

//Banner
.banner {
    background-size: cover;
    background-position: center;
    padding: 4em 3em;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 200px;

    .banner-text {
        font-family: "Oswald", sans-serif;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
        font-size: 3rem;
        color: $white-color;
        text-align: center;
    }
}


//Breadcrumbs 
.breadcrumb-wrapper {
    background: $red-color;

    ol {
        display: flex;
        list-style: none;
        font-family: "Saira", sans-serif;
        color: $white-color;
        text-transform: uppercase;
        margin: 0.5rem 0;

        li {
            text-decoration: underline;
            cursor: pointer;
            width: max-content;
            position: relative;
            top: 3px;
        }

        li.child {
            &::before {
                display: inline-block;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                content: "/";
            }
        }

        li.active {
            &::before {
                display: inline-block;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                content: "/";
            }

            &:last-child {
                text-decoration: none;
                cursor: auto;
            }
        }
    }
}


//content-section 
.content-section {
    background: $grey-color;
    padding: 4em 3em;
}

//body-content
.text-blue {
    color: $blue-color;
}

.text-white {
    color: $white-color;
}

.header-text {
    font-family: 'Oswald', sans-serif;
    font-size: 1.75em;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0.5rem;
    position: relative;
}

.content-text {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: .95rem;
    line-height: 1.5rem;
    padding-top: 1em;
    padding-bottom: 1em;
}

//accordion 
mat-expansion-panel.mat-expansion-panel.mat-elevation-z0.accordion-custom:first-child mat-expansion-panel-header {
    border-top: 1px solid #dfdfdf !important;
}

.mat-accordion .mat-expansion-panel {
    border-radius: 0;
}

mat-expansion-panel.mat-expansion-panel.mat-elevation-z0.accordion-custom mat-expansion-panel-header {
    background: #FAFAF9;
    border-top: 0px;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    border-radius: 0;

    .mat-expansion-panel-header-title {
        font-family: 'Saira', sans-serif;
        color: #0059A5;
    }

    &:last-child {
        border-bottom: 1px solid #dfdfdf;
    }
}

.mat-accordion.management mat-expansion-panel.mat-expansion-panel.mat-elevation-z0.accordion-custom mat-expansion-panel-header {
    border-top: 1px solid #dfdfdf !important;
}

.mat-expansion-panel-spacing {
    margin: 0 !important;
}

.mat-expansion-panel-body {
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
}

.mat-expansion-panel-header {
    height: 65px;
}

.mat-expansion-panel-header .mat-expansion-indicator::after {
    border-width: 0 1px 1px 0;
    color: #182164;
}

.mat-content {
    flex: inherit !important;

    .mat-expansion-panel-header-title {
        flex: 1;
    }
}

.management {
    .mat-content {
        flex: 1 !important;
    }
}

.mat-expansion-indicator::after {
    position: relative;
    top: -3px;
}

span.mat-expansion-indicator {
    transform: rotate(-90deg) !important;
}

.mat-expanded {
    span.mat-expansion-indicator {
        transform: rotate(0deg) !important;
    }
}

mat-expansion-panel.mat-expansion-panel.mat-elevation-z0.accordion-custom.quote {

    background: transparent;

    .mat-expansion-panel-header.border-0 {
        padding: 0;
        border: 0 !important;
        background: transparent;

        .header-text {
            color: #333;
        }

        &:hover {
            background: transparent;
        }
    }


    .mat-expansion-panel-body {
        padding: 0;
        border: 0;
    }
}

.warehouse-quenstionnaire {
    .mat-expansion-panel-content {
        .mat-expansion-panel-body {
            background: #f6f6f6;
        }
    }
}

//Forms 
.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.form-button-group {
    width: calc(100% - 140px);
}

mat-form-field.mat-form-field-appearance-outline {
    width: 100%;

    .mat-form-field-wrapper {
        margin: 0;

        .mat-form-field-flex {
            height: 50px;
            margin-top: 0;

            .mat-form-field-outline {
                background: #fff;
                top: 0;

                .mat-form-field-outline-start,
                .mat-form-field-outline-gap,
                .mat-form-field-outline-end {
                    border-radius: 0;
                }
            }

            .mat-form-field-infix {
                padding: initial;
            }

            .mat-form-field-suffix {
                top: 0.35em;
            }
        }
    }
}

.mat-flat-button.primary-btn {
    width: 140px;
    height: 49px;
    border-radius: 0;
    font-family: 'Saira', sans-serif;
    text-transform: uppercase;
    background: #c22a22;
    color: #fff;
}

label {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    color: $black-color;
    font-weight: 500;
    letter-spacing: .1em;
    margin-bottom: 0;
    padding-left: 0.3em;
    text-transform: capitalize;
    //added new for small device word wrap 22/9/2023
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mat-select {
    line-height: 28px;

    .mat-select-arrow-wrapper {
        height: 0;
    }
}

mat-form-field.mat-form-field-appearance-outline.textarea .mat-form-field-wrapper .mat-form-field-flex {
    height: auto;
    padding-bottom: 15px;
}

//table 
table {
    width: 100%;
    border-spacing: 0;
}

th.mat-header-cell {
    text-align: left;
    background: $grey-color;
    border-top: 4px solid $blue-color;
    border-bottom: 1px solid $blue-color !important;
    font-family: "Oswald", sans-serif;
    font-size: 0.85rem;
    text-transform: uppercase;
    font-weight: 500;
    color: $black-color;
}

th.mat-header-cell-shipment-info {
    text-align: center;
    background: $grey-color;
    border-top: 4px solid $blue-color;
    border-bottom: 1px solid $blue-color !important;
    font-family: "Oswald", sans-serif;
    font-size: 0.85rem;
    text-transform: uppercase;
    font-weight: 500;
    color: $black-color;
}

td.mat-cell {
    background: $grey-color;

    .material-icons {
        font-size: 1.2rem;
    }
}

td.mat-cell-shipment-info {
    text-align: center;
    background: $grey-color;

    .material-icons {
        font-size: 1.2rem;
    }
}

th.mat-header-cell,
th.mat-header-cell-shipment-info,
td.mat-cell,
td.mat-cell-shipment-info,
td.mat-footer-cell {
    padding: 15px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

//Tabs
.mat-tab-label .mat-tab-label-content {
    letter-spacing: 2px;
}


// Radio button
.mat-radio-button {
    margin-right: 15px;
    letter-spacing: 0;

    .mat-radio-label-content {
        letter-spacing: 0px;
        font-weight: 400;
        position: relative;
        top: 1px;
    }
}

// Checkbox
.mat-checkbox {
    margin-bottom: 7px;

    .mat-checkbox-layout .mat-checkbox-label {
        font-weight: 400;
        letter-spacing: 0;
        position: relative;
        top: 1px;
    }
}


// Datepicker 
mat-datepicker-toggle {
    position: absolute;
    right: 0;
    top: -7px;
}

sup {
    color: $red-color;
}

//Spinner 
.spinner-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1031;

    @keyframes spinner {
        0% {
            -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
        }

        100% {
            -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }

    @-webkit-keyframes spinner {
        0% {
            -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
        }

        100% {
            -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }

    .spinner {
        position: relative;
        -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
        transform: translate(-100px, -100px) scale(1) translate(100px, 100px);

        &>div {
            -webkit-animation: spinner 1s linear infinite;
            animation: spinner 1s linear infinite;
            top: 100px;
            left: 100px;

            &:after {
                -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
            }
        }

        &>div,
        &>div:after {
            position: absolute;
            width: 160px;
            height: 160px;
            top: 50vh;
            left: 50vw;
            border: 20px solid $red-color;
            border-top-color: transparent;
            border-radius: 50%;
        }
    }
}

// Common Validation Error & Success fields 
.error {
    padding: 1rem;
    font-family: 'Saira', sans-serif;
    color: $red-color;
    background-color: $white-color;
    border-color: $red-color;
    border: 2px solid $red-color;
    text-align: center;
    margin-bottom: 2rem;

    span {
        font-weight: 500;
    }
}

.success {
    padding: 1rem;
    font-family: 'Saira', sans-serif;
    color: $green-color;
    background-color: $white-color;
    border-color: $green-color;
    border: 2px solid $green-color;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 500;
}

.mat-tab-header-pagination.mat-tab-header-pagination-disabled,
.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    display: none !important;
}

.mat-tab-label-container {
    overflow: hidden;
    z-index: 1;
}

.bol .mat-radio-button .mat-radio-label-content,
.bol .mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
    font-weight: 300;
}

.tandc .mat-radio-button .mat-radio-label-content {
    font-size: 0.8rem;
}

td span {
    font-weight: 500;
    text-transform: uppercase;
}

.column {
    display: flex;
    flex-direction: column;

    mat-radio-button {
        margin-bottom: 10px;
    }
}

.readonly mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
    background: #e9e9e9;
}

input.mat-input-element {
    margin-top: 3px;
}

.display-block {
    display: block;

    app-time-picker {
        .form-group {
            .section-flex {
                mat-form-field {
                    .mat-form-field-wrapper {
                        padding-bottom: 7px;
                    }
                }
            }
        }
    }
}

.custom-error {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 12px;
    padding: 0 10px 0 10px;
}

span.mat-button-wrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.counter {
    position: relative;
}

.counter input.mat-input-element {
    width: calc(100% - 40px);
}

.counter-astrik {
    .mat-hint {
        top: 39px;
    }
}

.mat-hint {
    color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 36px;
    right: 30px;
    font-size: 12px;
    cursor: not-allowed;
    pointer-events: none;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

//Content full reset. 
.content-text {
    ul {
        li {
            p {
                margin: 0;
            }
        }
    }

    a {
        margin-top: 0.25rem;
        list-style: none;
        padding: 0;
        font-weight: 500;
        color: #0badc5;
        font-family: Saira, sans-serif;
        line-height: 2rem;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.service {
    ul {
        margin-top: 0.25rem;
        list-style: none;
        padding: 0 0.25rem;
        font-weight: 500;
        font-family: Saira, sans-serif;
        text-transform: uppercase;
        line-height: 2rem;
        color: #0badc5;
        cursor: pointer;
        display: inline-block;
        margin-bottom: 0;

        li {
            a {
                text-decoration: none;
            }

            span {
                font-size: 1rem;
                position: relative;
                top: 4px;
                margin-left: 10px;
            }
        }
    }
}


.content {
    ul {
        &+p {
            &+p {
                margin-top: 40px;
            }
        }
    }
}

.content {
    h4 {
        font-family: "Oswald", sans-serif;
        font-size: 1.75em;
        text-transform: uppercase;
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 0.5rem;
        padding: 1.5rem 0;
        position: relative;
        color: #182164;
    }

    h6 {
        b {
            font-size: 16px;
            font-weight: 500;
        }
    }
}

.resources-content p>a+span.material-icons {
    font-size: 1rem;
    position: relative;
    top: 4px;
    margin-left: 10px;
    color: #0badc5;
}

.resources-content p {
    margin: 0;
}

.resources-content p>a {
    text-decoration: none;
}


.cust-height {
    height: 100%;

    iframe {
        height: 100%;
    }
}

.holidays {

    h3 {
        text-transform: uppercase;
        font-weight: 500;
    }

    p {

        &:nth-child(3) {
            margin-top: 0;
        }

        b {
            font-weight: 500;
        }
    }

    table {
        border-collapse: collapse;

        tbody {
            tr {
                height: 56px;

                th {
                    padding-left: 24px;
                    text-align: left;
                    background: #F6F6F6;
                    border-top: 4px solid #182164;
                    border-bottom: 1px solid #182164 !important;
                    font-family: Oswald, sans-serif;
                    font-size: .85rem;
                    text-transform: uppercase;
                    color: #000;

                    p {
                        b {
                            font-weight: 500;
                        }
                    }

                    &:nth-child(2),
                    &:nth-child(3) {
                        padding-left: 0 !important;
                    }
                }

            }
        }

        tbody {
            tr {
                height: auto;

                td:first-of-type {
                    padding-left: 24px;
                }

                td {
                    padding: 0;
                    border-bottom-width: 1px;
                    border-bottom-style: solid;
                    border-color: #ddd;
                }
            }
        }
    }
}

.final-mile-content {
    h5 {
        margin-bottom: 35px;

        b {
            font-size: .95rem;
            font-weight: 500;
        }
    }
}

.contact-us {
    p {
        &:nth-child(1) {
            margin-top: 0;
            margin-bottom: 0;
        }

        &:nth-child(2) {
            margin-top: 0;
        }

        &:nth-child(3) {
            margin-bottom: 0;
        }

        &:nth-child(4) {
            margin-top: 0;
        }

        &:nth-child(5) {
            margin-bottom: 0;
        }

        &:nth-child(6) {
            margin-top: 0;
        }

        b {
            font-weight: 500;
        }
    }
}

.cookie-consent,
.cookie-consent:hover {
    background: transparent !important;
    color: #fff !important;
    border: none !important;
    font-family: 'Saira', sans-serif;
    text-transform: uppercase;
    font-size: 0.9rem !important;
}

.content-text-p {
    p {
        margin: 0;
    }
}

.scroller-bg {
    position: absolute;
    width: 100vw;
    width: calc(100vw - 17px);
    backdrop-filter: blur(50px);
    background-color: rgb(194 42 34 / 80%);
    color: #fff;
    font-family: "Saira", sans-serif;
    overflow: hidden;
    cursor: pointer;
    height: 47px;
    line-height: 38px;

    p {
        margin: 0;
        padding: 5px 10px;
    }
}

.animate {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -moz-animation: scroll-left 25s linear infinite;
    -webkit-animation: scroll-left 25s linear infinite;
    animation: scroll-left 25s linear infinite;
    white-space: nowrap; 
    padding-left: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

@-moz-keyframes scroll-left {
    0% {
        -moz-transform: translateX(0%);
    }

    100% {
        -moz-transform: translateX(-100%);
    }
}

@-webkit-keyframes scroll-left {
    0% {
        -webkit-transform: translateX(0%);
        //left: 100%;
    }

    100% {
        -webkit-transform: translateX(-100%);
        //left:0;
    }
}

@keyframes scroll-left {
    0% {
        -moz-transform: translateX(0%);
        -webkit-transform: translateX(0%);
        transform: translateX(0);
    }

    100% {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

@media screen and (max-width: 1900px) {
    .documents {
        background-position: center bottom !important;
    }
}

@media screen and (max-width: 1465px) {
    .transloading {
        background-position: center 0px !important;
    }
}

@media screen and (max-width: 1350px) {
    mat-ink-bar {
        display: none;
    }

    .mat-tab-labels {
        margin-top: 25px;
    }

    .mat-tab-label {
        border: 1px solid #ccc;
        border-top: 0;
    }

    .mat-tab-label {
        &:first-child {
            border-top: 1px solid #ccc;
        }
    }

    .mat-tab-labels {
        display: flex;
        flex-direction: column;
        height: 240px;
    }

    .mat-tab-label-active {
        background: #cc3029;
        opacity: 1 !important;
        color: #fff;
    }
}

@media screen and (max-width: 1210px) {
    .about-us {
        background-position: center top !important;
    }
}

@media screen and (max-width: 1185px) {
    .careers {
        background-position: center bottom !important;
    }
}

@media screen and (max-width: 1050px) {
    .banner {
        height: 180px;
    }
}

@media screen and (max-width: 1023px) {
    button.mat-focus-indicator[aria-expanded="true"]:after {
        border-bottom: 10px solid transparent;
    }

    .mat-menu-panel {
        display: none;
    }
}

@media screen and (max-width: 995px) {
    .banner {
        height: 100px;
    }

    .section-flex {
        flex-direction: column;

        mat-form-field {
            .mat-form-field-wrapper {
                padding-bottom: 0 !important;
            }
        }

        mat-button-toggle-group {
            padding-bottom: 1.34375em !important;
        }

    }
}

@media screen and (max-width: 768px) {
    .responsive {
        width: 100%;
        overflow-x: scroll;

        table {
            width: 625px;
        }
    }

    .banner {
        height: 180px;
    }

    .expedited,
    .final-mile,
    .warehousing,
    .transloading {
        background-position: center 0px !important;
    }

    mat-tab-body {
        .mat-tab-body-content {
            overflow: hidden;
        }
    }

    mat-checkbox {
        .mat-checkbox-layout {
            white-space: inherit;
        }
    }

    .mat-checkbox-layout .mat-checkbox-label {
        line-height: inherit;
    }

}

@media screen and (max-width: 425px) {

    //Breadcrumbs
    .breadcrumb-wrapper {
        ol {
            li.active {
                &:last-child {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: calc(100% - 165px);
                }
            }
        }
    }


    //Footer
    app-footer {
        .red-block {
            .content-text {
                padding: 0;
            }
        }
    }

    .mat-tab-label-container {
        flex-direction: column;
    }

    mat-radio-group[role="radiogroup"],
    mat-checkbox-group {
        display: flex;
        flex-direction: column;

        mat-radio-button {
            margin-bottom: 7px;
        }
    }
}

@media screen and (max-width: 374px) {

    .banner .banner-text {
        font-size: 2rem;
    }

    .content-section {
        padding: 2em;
    }

    ul.list-style {
        padding-left: 20px;
    }
}